export default {
    SUCCESS: {
        type:"success",
        icon:require("@/assets/promptPlus/success.png")
    },
    WANING: {
        type:"warning",
        icon:require("@/assets/promptPlus/warning.png")
    },
    MESSAGE: {
        type:"message",
        icon:require("@/assets/promptPlus/message.png")
    },
    ERROR: {
        type:"error",
        icon:require("@/assets/promptPlus/error.png")
    }
}