import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import { getStorage } from '@/utils/storage'

const routes = [
  {
    path: "/",
    redirect: "/sales"
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title: ""
    },
    component: () => import("@/views/Register/index.vue")
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: ""
    },
    component: () => import("@/views/Login/index.vue")
  },
  {
    path: "/sales",
    name: "sales",
    meta: {
      title: "鲜麦坊-无人售卖运营系统"
    },
    component: () => import("@/views/Sales/inde.vue")
  },
  {
    path: "/income",
    name: "income",
    meta: {
      title: "收益明细"
    },
    component: () => import("@/views/Income/index.vue")
  },
  {
    path: "/promotion",
    name: "promotion",
    meta: {
      title: "推广记录"
    },
    component: () => import("@/views/Promotion/index.vue")
  },
  {
    path: "/scanRegister",
    name: "scanRegister",
    meta: {
      title: "扫码注册"
    },
    component: () => import("@/views/ScanRegister/index.vue")
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    meta: {
      title: "忘记密码"
    },
    component: () => import("@/views/ForgetPassword/index.vue")
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "404页面"
    },
    component: () => import("@/views/404Page/index.vue")
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path == "/login" || to.path == "/register" || to.path == "/forgetPassword" || to.path == "/404") {
    next()
  } else {
    let token = getStorage("token")
    if(token && token != ""){
      next()
    }else{
      router.push({
        path:"/login"
      })
      next()
    }
  }

})
export default router
