import PromptCompent from "./promptComponent.vue"
import types from "./types"
import { createApp } from "vue"

const PromptPlus = (options) => {
    const promptApp = createApp(PromptCompent, options)
    let arr =[]
    Object.values(types).forEach(k => [
        arr.push(k.type)
    ])
    if(arr.includes(options.type) == true){
        showPrompt(promptApp)
    }else{
        return Promise.reject(`type值不存在${options.type}`)
    }
}
Object.values(types).forEach(t => {
    PromptPlus[t] = (options) => {
        options.type = t.type
        return PromptPlus(options)
    }
})

function showPrompt(app, duration) {
    const oel = document.createElement("div")
    const vm = app.mount(oel)
    document.body.appendChild(oel)
    vm.setVisiable(true)
    hidePrompt(app, vm, duration)
}

function hidePrompt(app, vm, duration) {
    let timer = setTimeout(async () => {
        await vm.setVisiable(false)
        app.unmount()
        clearTimeout(timer)
        timer = null
    }, duration || 3000)
}

export {
    types
}

export default PromptPlus;