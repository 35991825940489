import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PromptPlus from "@/components/plugins/index.js"
import pinia from "@/store/index"
import ElementPlus from "element-plus"
import Base64 from 'js-base64';  

import 'element-plus/dist/index.css'
import 'vant/lib/index.css'
import {
    Cell,
    Calendar
} from "vant"

const app = createApp(App)
app.config.globalProperties.$promptPlus = PromptPlus
app.use(pinia)
    .use(router)
    .use(ElementPlus)
    .use(Cell)
    .use(Calendar)
    .use(Base64)
    .mount('#app')
