<template>
  <router-view/>
</template>

<style lang="less">

body{
  margin: 0px;
  height: 0px;
  margin:  0 auto !important;
  font-size: 14px;
}
// 滚动条样式
::-webkit-scrollbar {
  width: 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
