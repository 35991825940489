<template>
    <transition name="prompt-transition">
        <div :class="styleClass" v-show="visiable">
            <img class="error-icon" :src="icon" alt="" srcset="">
            <span class="prompt-text">{{ message }}</span>
        </div>
    </transition>
</template>

<script setup>
import types from "./types"
import { computed, reactive, toRefs } from "vue"
const props = defineProps({
    type: "",
    message: "",
    duration: ""
})

const styleClass = computed(() => {
    return ['prompt-box', props.type]
})
const icon = computed(() => {
    let iconData = ""
    Object.values(types).forEach(t => {
        if (t.type === props.type) {
            iconData = t.icon
        }
    })
    return iconData
})
const state = reactive({
    visiable: false
})
const { visiable } = toRefs(state)
// 显示、隐藏提示框
const setVisiable = (val) => {
    return new Promise((resolve, reject) => {
        state.visiable = val
        let t = setTimeout(() => {
            clearTimeout(t)
            t = null
            resolve("")
        }, 300)
    })
}
// 暴露出该方法
defineExpose({
    setVisiable
})

</script>

<style lang="less" scoped>
.prompt-box {
    position: fixed;
    top: 50px;
    left: 50%;
    margin-left: -150px;
    display: flex;
    align-items: center;
    height: 50px;
    width: 300px;
    border-radius: 30px;
    box-sizing: border-box;
    z-index: 9999;

    .error-icon {
        position: absolute;
        left: 30px;
        width: 24px;
        height: 24px;
    }

    .prompt-text {
        width: inherit;
        text-align: center;
    }

    &.success {
        background-color: rgb(229, 253, 228);
        color: #087815;
    }

    &.warning {
        background-color: rgb(255, 248, 239);
        color: #d15807;
    }

    &.message {
        background-color: rgb(234, 238, 250);
        color: #095bb8;
    }

    &.error {
        background-color: rgb(243, 228, 231);
        color: #e73357;
    }
}

.prompt-transition-enter-active {
    transition: all .3s ease-in;
}

.prompt-transition-leave-active {
    transition: all .3s ease-out;
}

.prompt-transition-enter-from,
.prompt-transition-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>