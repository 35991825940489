export const getStorage = (key) => {
  let value = JSON.parse(localStorage.getItem(key))
  return value
}
export const setStorage = (key,val) => {
    localStorage.setItem(key,JSON.stringify(val))
}

export const removeStorage = (key) => {
    localStorage.removeItem(key)
}